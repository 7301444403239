@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .stepper {
    position: relative;
    border-radius: 5px;
    flex: 100%;
    /* width: 370px; */
    padding: 20px 20px 20px 40px;
  }

  .last {
    /* height: 78px; */
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .first {
    -webkit-clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }

  .center3 {
    position: absolute;
    height: 78px;
    clip-path: polygon(
      18.5px 50%,
      -0.5% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      -0.5% 100%
    );
  }

  .center {
    position: relative;
    clip-path: polygon(
      20px 50%,
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }
}
@font-face {
  font-family: "ArbFONTS-Regular";
  src: url("../public/assets/fonts/ArbFONTS-MyriadArabic-Bold.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ArabFont-normal";
  src: url("../public/assets/fonts/ArbFONTS-MyriadArabic-Regular.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ArbFONTS-Bold";
  src: url("../public/assets/fonts/ArbFONTS-MyriadArabic-Bold.otf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Typewriter-Regular";
  src: url("../public/assets/fonts/Typewriter-Serial-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mada-Regular";
  src: url("../public/assets/fonts/Mada-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JF-Flat-regular";
  src: url("../public/assets/fonts/JF-Flat-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Regular";
  src: url("../public/assets/fonts/Metropolis-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Messri";
  src: url("../public/assets/fonts/ElMessiri-VariableFont_wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
